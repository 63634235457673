import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Banner from "../components/Banner/Banner"

const Lesiones = () => {
  const data = useStaticQuery(graphql`
    query {
      plasma: file(relativePath: { eq: "plasma.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Tratamientos Biológicos" />
        <div className={classes.root}>
          <Banner content="Tratamientos Biológicos con Factores de Crecimiento" />
          <Img
            className={classes.heroImg}
            fluid={data.plasma.childImageSharp.fluid}
          />
          <h3 className={classes.title}>
            Tratamientos Biológicos con Factores de Crecimiento
          </h3>
          <p className={classes.content}>
            Esta novedosa técnica es empleada con éxito en nuestros pacientes.
            El tratamiento consiste en la utilización de sangre del propio
            paciente para procesarla y obtener un plasma rico en factores de
            crecimiento (proteínas), que se coloca en la zona a tratar. A partir
            de ese momento, la zona lesionada comienza a cicatrizar y, al cabo
            de 7 días, hay un número de células 4 veces superior que en un
            proceso reparador natural o con un tratamiento convencional, lo cual
            acelera los tiempos de recuperación. Puede utilizarse en estadíos
            iniciales de la artrosis, necrosis y lesiones osteocondrales de
            cadera y rodilla, desgarros musculares, lesiones ligamentarias y
            tendinosas (hombro, rodilla, codo, cadera, etc.). También pueden
            utilizarse en combinación con distintos procedimientos quirúrgicos,
            para mejorar los resultados de los mismos y acelerar los tiempos de
            recuperación. Este procedimiento puede realizarse tanto en quirófano
            como en el mismo consultorio.
          </p>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Lesiones
